
import {
    PlusOutlined,
    SearchOutlined
} from '@ant-design/icons-vue';
import Editor from './editor';

export default {
    name: "ConfigNotice",
    components: {
        PlusOutlined,
        SearchOutlined,
        Editor
    },
    data() {
        return {
            // 列表数据
            data: [],
            // 搜索表单
            search: {
                keyword: ''
            },
            // 分页参数
            page: {
                page: 1,
                limit: 10
            },
            // 数据总数
            count: 0,
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 列表加载状态
            loading: false
        };
    },
    mounted() {
        this.query();
    },
    methods: {
        /* 查看更多 */
        handleShowMore(row) {
            // 初始化ShowMore数据
            if (row.showMore == undefined) {
                row.showMore = true
            } else {
                row.showMore = !row.showMore;
            }

            if (row.showMore) {
                // 展开
                this.$refs[`content${row.id}`].style.maxHeight = 'unset';
            } else {
                // 收起
                this.$refs[`content${row.id}`].style.maxHeight = '240px';
            }
        },
        /* 查询数据 */
        query() {
            this.loading = true;

            this.$http.get('/admin/notice/list', {
                params: Object.assign({}, this.search, this.page)
            }).then(res => {
                this.loading = false;

                if (res.data.code === 0) {
                    this.data = res.data.data;
                    this.count = res.data.count;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('share.requesting', 0);

            this.$http.post('/admin/notice/delete', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.query();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 修改状态 */
        editState(checked, row, field) {
            this.$http.get(`/admin/notice/state/${row.id}?${field}=${(checked ? 1 : 2)}`).then(res => {
                if (res.data.code === 0) {
                    row[field] = checked;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        }
    }
}
